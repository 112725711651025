<template>
  <div style="color: #000;" id="print-statement" v-show="false">
    <div><span style="border-bottom: 1px solid #000;">ООО "Сургутремимпорттехника"</span></div>
    <div>Адрес: 628400, Российская федерация, Тюменская область, ХМАО-Югра, г.Сургут, Нефтеюганское ш., д.24/1</div>
    <div style="text-align: center; margin: 30px auto;"><h2>АКТ № {{data.slug}} от {{currentDate}}</h2></div>
    <div v-if="data.client">Заказчик: {{data.client.name}}</div>
    <div style="margin: 20px auto;"><h3>Ремонтные работы</h3></div>
    <div>
      <table style="width: 100%;" border="1" cellspacing="0" cellpadding="3">
        <thead>
          <tr>
            <th>#</th>
            <th>Наименование работы (услуги)</th>
            <th>Ед. изм.</th>
            <th>Количество</th>
            <th v-if="prices">Цена</th>
            <th v-if="prices">Сумма</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, i) in data.works" :key="item.name">
            <td align="right">{{ i+1 }}</td>
            <td>{{ item.name }}</td>
            <td align="center">чел.час</td>
            <td align="right">{{ item.pivot.hours }}</td>
            <td align="right" v-if="prices">{{ fixed2(endPrice(item)) }}</td>
            <td align="right" v-if="prices">{{ fixed2(endPrice(item) * item.pivot.hours) }}</td>
          </tr>
          <tr v-for="(item, i) in data.parts" :key="item.name">
            <td align="right">{{ data.works.length + i + 1 }}</td>
            <td>{{ item.name }}</td>
            <td align="center">{{ item.unit }}</td>
            <td align="right">{{ item.pivot.count }}</td>
            <td align="right" v-if="prices">{{ fixed2(endPrice(item)) }}</td>
            <td align="right" v-if="prices">{{ fixed2(endPrice(item) * item.pivot.count) }}</td>
          </tr>
          <tr v-if="prices">
            <td colspan="5" align="right"><strong>Итого:</strong></td>
            <td align="right">{{totalSum}}</td>
          </tr>
          <tr v-if="prices">
            <td colspan="5" align="right"><strong>Без налога (НДС):</strong></td>
            <td align="center">-</td>
          </tr>
          <tr v-if="prices">
            <td colspan="5" align="right"><strong>Всего (с учетом НДС):</strong></td>
            <td align="right">{{totalSum}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div style="font-style: italic; margin: 30px auto;" v-if="prices">
      Всего оказано услуг на сумму {{totalSum}} рублей 00 копеек
    </div>
    <div style="margin: 30px auto;">
      Вышеперчисленные услуги выполнены полностью и в срок. Заказчик претензий по объему, качеству и срокам оказания услуг не имеет.
    </div>
    <div style="text-align: center; display: flex; justify-content: space-between;">
      <div style="flex-basis: 10%;">Исполнитель</div>
      <div style="flex-basis: 40%; padding: 0 20px;">
        <div style="border-bottom: 1px solid #000; display: flex; justify-content: space-between;">
          <div>Директор</div>
          <div>/Андреев О.Е./</div>
        </div>
        <div style="font-size: xx-small;">ПОДПИСЬ</div>
      </div>
      <div style="flex-basis: 10%; padding-right:20px;">Заказчик</div>
      <div style="flex-basis: 40%;">
        <div style="border-bottom: 1px solid #000; display: flex; justify-content: space-between;">
          <div style="visibility: hidden;">1</div>
        </div>
        <div style="font-size: xx-small;">ПОДПИСЬ</div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      data: Object,
      prices: Boolean,
    },
    computed: {
      confirmedParts () {
        return this.data.parts.filter(d => d.pivot.confirmed)
      },
      totalWorks () {
        return this.data.works.map(d => d.pivot.hours * this.endPrice(d))
          .reduce(function(acc, val) { return acc + val; }, 0)
      },
      totalParts () {
        return this.data.parts.map(d => d.pivot.count * this.endPrice(d))
          .reduce(function(acc, val) { return acc + val; }, 0)
      },
      totalSum () {
        return this.fixed2(this.totalWorks + this.totalParts)
      },
      currentDate () {
        return new Date().toLocaleDateString('ru-Ru', {dateStyle: 'long', month: 'short'})
      },
    },
  }
</script>