<template>
  <div>
    <v-row align="baseline" justify="space-between">
      <v-col cols="12" md="6">
        <div class="display-1 grey--text text--darken-4">{{title}}</div>
      </v-col>
      <v-col cols="4" md="2">
        <v-text-field label="Номер ведомости" v-model="data.slug" filled readonly></v-text-field>
      </v-col>
      <v-col cols="4" md="2">
        <v-text-field label="Дата создания" v-model="data.created_date" filled readonly></v-text-field>
      </v-col>
      <v-col cols="4" md="2">
        <v-text-field 
          v-if="data.status"
          label="Статус" 
          v-model="data.status.name" 
          :background-color="getStatusColor(data.status)" 
          dark
          filled 
          readonly>
        </v-text-field>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  export default {
    props: {
      title: String,
      data: Object,
    },
  }
</script>