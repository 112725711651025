<template>
  <div>
    <div class="title">{{title}} ({{data.length}})</div>

    <v-row align="start" justify="center" v-if="data.length === 0">
      <v-col cols="12">
        <v-alert type="warning">Работы еще не добавлены</v-alert>
      </v-col>
    </v-row>

    <v-row align="start" justify="center" v-for="(item, i) in data" :key="item.id">
      <v-col cols="4" md="4" class="pr-1">
        <v-text-field label="Работа" v-model="item.name" filled readonly></v-text-field>
      </v-col>
      <v-col class="px-1">
        <v-text-field
          label="Часы"
          v-model="item.pivot.hours"
          @focus="disableSaving"
          @blur="enableSaving"
          min="1"
          max="9999"
          step="1"
          type="number"
          filled
          background-color="grey lighten-5"
        ></v-text-field>
      </v-col>
      <v-col class="px-1">
        <v-text-field
          label="Цена часа"
          v-model="item.pivot.price"
          @focus="disableSaving"
          @blur="enableSaving"
          min="1"
          max="9999999"
          step=".01"
          type="number"
          filled
          background-color="grey lighten-5"
        ></v-text-field>
      </v-col>
      <v-col class="px-1">
        <v-text-field
          label="Сумма"
          :value="fixed2(item.pivot.hours * endPrice(item))"
          readonly
          filled></v-text-field>
      </v-col>
      <v-col class="pl-1">
        <v-btn
          x-large
          block
          outlined
          color="error"
          :disabled="userIsKeeper"
          @click="data.splice(i, 1)">Удалить</v-btn>
      </v-col>
    </v-row>

    <v-row align="start" justify="end" v-if="!userIsKeeper">
      <v-col cols="4" md="3">
        <v-text-field
          label="Стоимость всех работ"
          :value="fixed2(totalPrice)"
          background-color="teal"
          dark
          readonly
          filled></v-text-field>
      </v-col>
      <v-col cols="4" md="2">
        <v-btn
          x-large
          block
          color="primary"
          :disabled="statusIsClosed(status)"
          @click="dialog = true">Добавить</v-btn>
      </v-col>
    </v-row>

    <!-- DIALOG -->
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Добавить - {{title}}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Поиск"
              class="my-5"
              single-line
              hide-details
            ></v-text-field>
            <v-data-table
              v-model="tempItems"
              :headers="[{text: 'Наименование', value: 'name', width: '100%'}]"
              :items="filteredItems"
              :single-select="false"
              :items-per-page="8"
              :search="search"
              @click:row="selectRow"
              hide-default-footer
              item-key="id"
              show-select
              class="elevation-1"
              :page.sync="page"
              @page-count="pageCount = $event"
            >
            </v-data-table>
            <div class="text-center pt-2">
              <v-pagination v-model="page" :length="pageCount"></v-pagination>
            </div>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn x-large class="px-5" color="secondary" @click="tempItems = [], dialog = false">Отмена</v-btn>
          <v-btn x-large class="px-5" color="info" @click="assignItems(), dialog = false">Выбрать</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    props: {
      title: String,
      data: Array,
      items: Array,
      status: Object,
    },
    data () {
      return {
        dialog: false,
        tempItems: [],
        page: 1,
        pageCount: 0,
        search: ''
      }
    },
    computed: {
      filteredItems () {
        return this.items.filter(i => !this.data.find(d => d.id === i.id))
      },
      totalPrice () {
        return this.data.map(d => d.pivot.hours * this.endPrice(d))
          .reduce(function(acc, val) { return acc + val; }, 0)
      }
    },
    methods: {
      assignItems () {
        this.tempItems.forEach((p) => {
          if (!this.data.find(d => d.id === p.id)) {
            this.data.push(this.items.find(i => i.id === p.id))
          }
        })
        this.tempItems = []
      },
      selectRow (row) {
        if (this.tempItems.includes(row)) {
          this.tempItems.splice(this.tempItems.indexOf(row), 1)
        } else {
          this.tempItems.push(row)
        }
      },
      disableSaving () {
        this.$emit('disable-saving')
      },
      enableSaving () {
        this.$emit('enable-saving')
        this.save()
      },
      save () {
        this.$emit('save')
      },
    },
  }
</script>