<template>
  <div>
    <div class="title">{{title}} ({{unconfirmedItems.length}})</div>
    
    <v-row align="start" justify="center" v-if="unconfirmedItems.length === 0 && data.length > 0">
      <v-col cols="12">
        <v-alert type="success">Все материалы выданы</v-alert>
      </v-col>
    </v-row>
    <v-row align="start" justify="center" v-if="data.length === 0">
      <v-col cols="12">
        <v-alert type="warning">Материалы еще не добавлены</v-alert>
      </v-col>
    </v-row>

    <v-row align="start" justify="center" v-for="item in unconfirmedItems" :key="item.id">
      <v-col v-if="userIsKeeper">
        <v-btn
          x-large
          block
          outlined
          color="success"
          class="px-2"
          @click="confirm([item.id])">Выдать</v-btn>
      </v-col>
      <v-col cols="4" md="6" class="d-flex">
        <v-text-field label="Материал" v-model="item.name" filled readonly></v-text-field>
      </v-col>
      <v-col>
        <v-text-field label="Единица" v-model="item.unit" filled readonly></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          v-if="item.pivot"
          label="Количество"
          min="1"
          max="999"
          step="1"
          v-model="item.pivot.count"
          @focus="disableSaving"
          @blur="enableSaving"
          filled
          :disabled="userIsMaster && statusIsCloseDone(status)"
          background-color="grey lighten-5"
          type="number"></v-text-field>
      </v-col>
      <v-col>
        <v-btn
          x-large
          block
          outlined
          color="error"
          class="px-2"
          :disabled="userIsMaster && statusIsCloseDone(status)"
          @click="data.splice(data.indexOf(item), 1)">Удалить</v-btn>
      </v-col>
    </v-row>

    <v-row align="start" justify="end">
      <!-- <v-col cols="6" md="5" offset-md="5">
        <v-btn x-large block color="success">Скопировать из предыдущей</v-btn>
      </v-col> -->
      <v-col cols="4" md="2">
        <v-btn
          x-large
          block
          color="primary"
          :disabled="statusIsCloseDone(status)"
          @click="openDialog">Добавить</v-btn>
      </v-col>
    </v-row>

    <v-row align="start" justify="end" v-if="userIsKeeper">
      <v-col cols="4" md="2">
        <v-btn
          x-large
          block
          color="success"
          :disabled="statusIsCloseDone(status) || unconfirmedItems.length === 0"
          @click="confirm(allItemsIds)">Выдать все</v-btn>
      </v-col>
    </v-row>

    <!-- DIALOG -->
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Добавить - {{title}}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Поиск"
              class="my-5"
              single-line
              hide-details
            ></v-text-field>
            <v-data-table
              v-model="tempItems"
              :headers="[{text: 'Наименование', value: 'name', width: '100%'}]"
              :items="filteredItems"
              :single-select="false"
              :items-per-page="8"
              :search="search"
              :custom-filter="customFilter"
              @click:row="selectRow"
              hide-default-footer
              item-key="id"
              show-select
              class="elevation-1"
              :page.sync="page"
              @page-count="pageCount = $event"
            >
            </v-data-table>
            <div class="text-center pt-2">
              <v-pagination v-model="page" :length="pageCount"></v-pagination>
            </div>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn x-large class="px-5" color="secondary" @click="closeDialog">Отмена</v-btn>
          <v-btn x-large class="px-5" color="info" @click="assignItems">Выбрать</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    props: {
      title: String,
      data: Array,
      items: Array,
      status: Object,
    },
    data () {
      return {
        dialog: false,
        tempItems: [],
        page: 1,
        pageCount: 0,
        search: ''
      }
    },
    computed: {
      filteredItems () {
        return this.items.filter(i => !this.data.find(d => d.id === i.id))
      },
      unconfirmedItems () {
        return this.data.filter(d => !d.pivot.confirmed)
      },
      allItemsIds () {
        return this.data.map(d => d.id)
      }
    },
    methods: {
      assignItems () {
        this.tempItems.forEach((p) => {
          if (!this.data.find(d => d.id === p.id)) {
            this.data.push(this.items.find(i => i.id === p.id))
          }
        })
        this.closeDialog()
      },
      openDialog () {
        this.dialog = true
      },
      closeDialog () {
        this.dialog = false
        this.tempItems = []
      },
      confirm (ids) {
        this.$emit('confirm-item', ids)
      },
      selectRow (row) {
        if (this.tempItems.includes(row)) {
          this.tempItems.splice(this.tempItems.indexOf(row), 1)
        } else {
          this.tempItems.push(row)
        }
      },
      disableSaving () {
        this.$emit('disable-saving')
      },
      enableSaving () {
        this.$emit('enable-saving')
        this.save()
      },
      save () {
        this.$emit('save')
      },
      customFilter (value, search) {
        return value != null &&
          search != null &&
          typeof value === 'string' &&
          this.checkSubstrings(search.split(' '), value)
      },
      checkSubstrings(searches, value) {
        for (let i = 0; i < searches.length; i++) {
          if (!value.toString().toLocaleLowerCase().includes(searches[i].toString().toLocaleLowerCase())) {
            return false
          }
        }
        return true
      }
    },
  }
</script>