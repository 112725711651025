<template>
  <div>
    <v-row align="start" justify="center">
      <v-col cols="8" md="10">
        <v-text-field
          :label="title"
          v-if="selectedItems"
          v-model="selectedItems.name"
          filled
          readonly>
        </v-text-field>
        <v-skeleton-loader v-if="!selectedItems" class="mx-auto" type="image" height="56px"></v-skeleton-loader>
      </v-col>
      <v-col cols="4" md="2">
        <v-btn
          x-large
          block
          color="primary"
          :disabled="userIsKeeper || statusIsClosed(status)"
          @click="dialog = true"
          v-text="selectedItems ? 'Изменить' : 'Клиент'"
        ></v-btn>
      </v-col>
    </v-row>

    <!-- DIALOG -->
    <v-dialog v-model="dialog" max-width="1000px">
      <v-card>
        <v-card-title>
          <span class="headline">{{title}} - Выбор</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Поиск"
              class="my-5"
              outlined
              single-line
              hide-details
            ></v-text-field>
            <v-data-table
              v-model="temp"
              :headers="[{text: 'Наименование', value: 'name', width: '100%'}]"
              :items="items"
              :single-select="true"
              :items-per-page="8"
              :search="search"
              @click:row="selectRow"
              hide-default-footer
              item-key="id"
              show-select
              class="elevation-1"
              :page.sync="page"
              @page-count="pageCount = $event"
            >
            </v-data-table>
            <div class="text-center pt-2">
              <v-pagination v-model="page" :length="pageCount"></v-pagination>
            </div>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn x-large class="px-5" color="secondary" @click="cancel">Отмена</v-btn>
          <v-btn x-large class="px-5" color="info" @click="save">Выбрать</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
  export default {
    props: {
      title: String,
      data: Number,
      items: Array,
      status: Object,
    },
    data () {
      return {
        dialog: false,
        temp: [],
        page: 1,
        pageCount: 0,
        search: '',
      }
    },
    computed: {
      selectedItems () {
        return this.items.find(d => d.id === this.data)
      },
    },
    methods: {
      save () {
        this.$emit('update-val', this.temp)
        this.temp = []
        this.dialog = false
      },
      cancel () {
        this.temp = []
        this.dialog = false
      },
      selectRow (row) {
        if (this.temp.length > 0) {
          this.temp.splice(0, 1)
        }
        this.temp.push(row)
      },
    }
  }
</script>

<style scoped>
  .v-data-table table tbody tr td:nth-of-type(0) {
    width: 5% !important;
  }
</style>