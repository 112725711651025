<template>
  <div>
    <v-row align="start" justify="center">
      <v-col cols="12" md="12">
        <v-textarea
          outlined
          v-model="text"
          :readonly="readonly"
          :label="title"
          @change="update"
        ></v-textarea>
      </v-col>
    </v-row>
    <v-row align="start" justify="end" v-if="!statusIsClosed(status)">
      <v-col cols="6" md="4" class="pt-0">
        <v-btn color="success" outlined x-large block>Сохранить комментарий</v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  export default {
    props: {
      title: String,
      data: String,
      status: Object,
      readonly: Boolean,
    },
    data () {
      return {
        text: ''
      }
    },
    created () {
      this.text = this.data
    },
    methods: {
      update () {
        this.$emit('update-val', this.text)
      }
    },
  }
</script>