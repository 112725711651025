<template>
  <div>

    <v-row align="start" justify="center">
      <v-col cols="2" md="3">
        <v-text-field 
          filled
          :readonly="!selectedItems || statusIsCloseDone(status)"
          v-if="selectedItems"
          :value="equipSlug"
          label="Номер оборудования" 
          ref="equipSlugInput"
          maxlength="100"
        ></v-text-field>
        <v-skeleton-loader v-if="!selectedItems" class="mx-auto" type="image" height="56px"></v-skeleton-loader>
      </v-col>
      <v-col cols="4" md="5">
        <v-text-field 
          filled
          :readonly="!selectedItems || statusIsCloseDone(status)"
          v-if="selectedItems"
          :value="equipName"
          label="Название оборудования" 
          ref="equipNameInput"
          maxlength="100"
        ></v-text-field>
        <v-skeleton-loader v-if="!selectedItems" class="mx-auto" type="image" height="56px"></v-skeleton-loader>
      </v-col>
      <v-col cols="3" md="2">
        <v-btn
          x-large
          block
          color="primary"
          :disabled="!selectedItems || statusIsCloseDone(status)"
          @click="updateEquipmentItem"
          v-text="'Сохранить'"
        ></v-btn>
      </v-col>
      <v-col cols="3" md="2">
        <v-btn
          x-large
          block
          color="primary"
          :disabled="statusIsCloseDone(status) || userIsKeeper"
          @click="dialog = true"
          v-text="'Изменить'"
        ></v-btn>
      </v-col>
    </v-row>

    <!-- DIALOG -->
    <v-dialog v-model="dialog" persistent max-width="1000px">
      <v-card>
        <v-card-title>
          <span class="headline">{{title}} - Выбор</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-tabs
              v-model="tab"
              background-color="transparent"
              grow
            >
              <v-tab><div style="font-size: large">Выбрать из журнала</div></v-tab>
              <v-tab><div style="font-size: large">Добавить новое</div></v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">

              <v-tab-item>
                <v-container>
                  <v-row>
                    <v-col>
                      <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Поиск"
                        single-line
                        outlined
                        hide-details
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-data-table
                        v-model="temp"
                        :headers="[{text: 'ID', value: 'slug', width: '1%'},{text: 'Наименование', value: 'name', width: '100%'}]"
                        :items="items"
                        :single-select="true"
                        :items-per-page="7"
                        :search="search"
                        @click:row="selectRow"
                        hide-default-footer
                        item-key="id"
                        show-select
                        class="elevation-1"
                        :page.sync="page"
                        @page-count="pageCount = $event"
                      >
                      </v-data-table>
                      <div class="text-center pt-2">
                        <v-pagination v-model="page" :length="pageCount"></v-pagination>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>  
              </v-tab-item>

              <v-tab-item>
                <v-container>
                  <v-row align="baseline" justify="center">
                    <v-col cols="12" class="pt-5">
                      <div class="headline">
                        Введите название оборудования. Номер оставьте пустым, чтобы он присвоился автоматически
                      </div>
                    </v-col>
                    <v-col cols="3" class="py-0">
                      <v-text-field
                        v-model="newItemSlug"
                        label="Номер"
                        class="my-5"
                        :error="newItemSlugError"
                        single-line
                        hide-details
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" class="py-0 px-0">
                      <v-text-field
                        v-model="newItem"
                        label="Название оборудования"
                        class="my-5"
                        :error="newItemError"
                        single-line
                        hide-details
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="3" class="py-0">
                      <v-btn
                        x-large
                        block
                        @click="addNewItem"
                        class="secondary">Добавить</v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" class="py-5">
                      <v-alert dense outlined type="error" v-if="newItemSlugError">
                        Оборудование с таким номером уже есть в базе
                      </v-alert>
                      <v-alert dense outlined type="error" v-if="newItemError">
                        Название должно быть от 3 до 255 символов длиной
                      </v-alert>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>

            </v-tabs-items>

          </v-container>
          
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn x-large class="px-5" color="secondary" @click="cancel">Отмена</v-btn>
          <v-btn x-large class="px-5" color="info" v-if="tab === 0" @click="save">Выбрать</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
  export default {
    props: {
      title: String,
      data: Number,
      items: Array,
      status: Object,
    },
    data () {
      return {
        tab: 0,
        dialog: false,
        temp: [],
        page: 1,
        pageCount: 0,
        search: '',
        newItem: '',
        newItemSlug: '',
        newItemError: false,
        newItemSlugError: false,
      }
    },
    computed: {
      selectedItems () {
        return this.items.find(d => d.id === this.data)
      },
      equipSlugPlusName () {
        if (this.selectedItems) {
          return this.selectedItems.slug + ' / ' + this.selectedItems.name
        } else {
          return ''
        }
      },
      equipSlug () {
        if (this.selectedItems) {
          return this.selectedItems.slug
        } else {
          return ''
        }
      },
      equipName () {
        if (this.selectedItems) {
          return this.selectedItems.name
        } else {
          return ''
        }
      },
      equipNameRowsNumber () {
        return Math.ceil(this.equipName.length / 20)
      },
    },
    methods: {
      save () {
        this.$emit('update-val', this.temp)
        this.temp = []
        this.dialog = false
        this.newItemError = false
        this.newItemSlugError = false
      },
      cancel () {
        this.temp = []
        this.dialog = false
        this.newItemError = false
        this.newItemSlugError = false
      },
      selectRow (row) {
        if (this.temp.length > 0) {
          this.temp.splice(0, 1)
        }
        this.temp.push(row)
      },
      addNewItem () {
        if (this.newItem.length < 3 || this.newItem.length > 255) {
          this.newItemError = true
          return
        } else {
          this.newItemError = false
        }
        this.$axios.post('equipments', {name: this.newItem, slug: this.newItemSlug || 'NEWSLUG'})
          .then(r => {
            if (r.data.id) {
              this.$emit('insert-val', r.data)
              this.selectRow(r.data)
              this.save()
            } else if (r.data.error) {
              this.newItemSlugError = true
            }
          })
      },
      updateEquipmentItem () {
        if (this.selectedItems) {
          let selectedItem = Object.assign({slug: 'POPOPO'}, this.selectedItems)
          selectedItem.slug = this.$refs.equipSlugInput.$el.querySelector('input').value
          selectedItem.name = this.$refs.equipNameInput.$el.querySelector('input').value
          if (selectedItem.slug.length < 1 || selectedItem.name.length < 1) {
            return;
          }
          this.$axios.patch('equipments/' + selectedItem.id, selectedItem)
            .then((r) => {
              if (r.data.id) {
                alert('Оборудование обновлено');
              }
            })
            .catch(error => {
              this.crudError(error)
            })
            .finally(() => {
              this.loading = false
            })
        }
      },
    }
  }
</script>