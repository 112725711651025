<template>
    <v-row align="start" justify="space-between">
      <v-col cols="4">
        <v-alert
          text
          dense
          prominent
          color="teal"
          icon="mdi-clock-fast"
          border="left"
        >
          <div>Стоимость<br> работ:</div>
          <div class="title">{{fixed2(totalWorks)}}</div>
        </v-alert>
      </v-col>
      <v-col cols="4">
        <v-alert
          text
          dense
          prominent
          color="teal"
          icon="mdi-cog-outline"
          border="left"
        >
          <div class="">Стоимость<br> материалов:</div>
          <div class="title">{{fixed2(totalParts)}}</div>
        </v-alert>
      </v-col>
      <v-col cols="4">
        <v-alert
          text
          dense
          prominent
          color="teal"
          icon="mdi-sigma"
          border="left"
        >
          <div class="">Общая<br> стоимость:</div>
          <div class="title">{{fixed2(totalSum)}}</div>
        </v-alert>
      </v-col>
    </v-row>
</template>

<script>
  export default {
    props: {
      works: Array,
      parts: Array,
    },
    computed: {
      confirmedParts () {
        return this.parts.filter(d => d.pivot.confirmed)
      },
      unconfirmedParts () {
        return this.parts.filter(d => !d.pivot.confirmed)
      },
      totalWorks () {
        return this.works.map(d => d.pivot.hours * this.endPrice(d))
          .reduce(function(acc, val) { return acc + val; }, 0)
      },
      totalParts () {
        return this.parts.map(d => d.pivot.count * this.endPrice(d))
          .reduce(function(acc, val) { return acc + val; }, 0)
      },
      totalSum () {
        return this.totalWorks + this.totalParts
      },
    },
  }
</script>