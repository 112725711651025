<template>
  <div v-if="filteredItems.length">
    <div class="title">{{title}} ({{filteredItems.length}})</div>

    <v-row align="start" justify="center" v-for="item in filteredItems" :key="item.id">
      <v-col cols="6" md="6" class="d-flex">
        <v-badge color="success" overlap right style="width: 100%">
          <template v-slot:badge>
            <v-icon dark>
              mdi-check
            </v-icon>
          </template>
          <v-text-field label="Материал" v-model="item.name" block filled readonly></v-text-field>
        </v-badge>
      </v-col>
      <v-col>
        <v-text-field label="Единица" v-model="item.unit" filled readonly></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          filled
          v-if="item.pivot"
          label="Количество"
          v-model="item.pivot.count"
          readonly></v-text-field>
      </v-col>
      <v-col v-if="!userIsMaster">
        <v-btn
          x-large
          block
          outlined
          color="error"
          class="px-2"
          @click="unconfirm(item.id)">Убрать</v-btn>
      </v-col>
    </v-row>

  </div>
</template>

<script>
  export default {
    props: {
      title: String,
      data: Array,
      items: Array,
      status: Object,
    },
    computed: {
      filteredItems () {
        return this.data.filter(d => d.pivot.confirmed)
      }
    },
    methods: {
      unconfirm (id) {
        this.$emit('remove-item', id)
      },
    },
  }
</script>