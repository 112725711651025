<template>
  
  <v-container class="fill-width" fluid>
    <v-card :loading="loading">
      <v-card-text>

        <StatementHeader 
          :title="title"
          :data="data"
        />

        <EquipmentsInput 
          title="Оборудование"
          :data="data.equipment_id"
          :items="equipments"
          @update-val="updateEquipment"
          @insert-val="insertEquipment"
          :status="data.status"
        />

        <ClientsInput 
          title="Клиент"
          :data="data.client_id"
          :items="clients"
          @update-val="updateClient"
          :status="data.status"
        />

        <WorksList 
          v-if="!userIsAdmin"
          title="Необходимые ремонтные работы"
          :data="data.works"
          :items="works"
          :status="data.status"
          @disable-saving="disableSaving"
          @enable-saving="enableSaving"
          @save="save"
        />
        <WorksListAdmin
          v-if="userIsAdmin" 
          title="Необходимые ремонтные работы"
          :data="data.works"
          :items="works"
          :status="data.status"
          @disable-saving="disableSaving"
          @enable-saving="enableSaving"
          @save="save"
        />

        <CommentInput 
          title="Комментарий администратора"
          :data="data.comment_admin"
          :status="data.status"
          @update-val="updateAdminComment"
          v-if="!statusIsDraft(data.status)"
          :readonly="statusIsClosed(data.status) || userIsKeeper || userIsMaster"
          style="display: none;"
        />
        <CommentInput 
          title="Комментарий мастера"
          :data="data.comment_master"
          :status="data.status"
          @update-val="updateMasterComment"
          :readonly="statusIsCloseDone(data.status) || !userIsMaster"
        />

        <PartsListConfirmed 
          v-if="!userIsAdmin" 
          title="Выданные материалы для ремонта"
          :data="data.parts"
          :items="parts"
          :status="data.status"
          @remove-item="unconfirmPart"
        />

        <PartsList 
          v-if="!userIsAdmin" 
          title="Требуемые материалы для ремонта"
          :data="data.parts"
          :items="parts"
          :status="data.status"
          @confirm-item="confirmParts"
          @disable-saving="disableSaving"
          @enable-saving="enableSaving"
          @save="save"
        />

        <PartsListAdmin 
          v-if="userIsAdmin" 
          title="Выданные материалы для ремонта"
          title2="Требуемые материалы для ремонта"
          :data="data.parts"
          :items="parts"
          :status="data.status"
          @confirm-item="confirmParts"
          @remove-item="unconfirmPart"
          @disable-saving="disableSaving"
          @enable-saving="enableSaving"
          @save="save"
        />

        <CommentInput 
          title="Комментарий администратора"
          :data="data.comment_admin"
          :status="data.status"
          @update-val="updateAdminComment"
          v-if="!statusIsDraft(data.status)"
          :readonly="statusIsClosed(data.status) || userIsKeeper || userIsMaster"
        />

        <TotalPrice
          v-if="userIsAdmin" 
          :works="data.works"
          :parts="data.parts"
        />

        <v-row align="end" justify="end" class="pt-10">
          <v-col cols="4" v-if="(!statusIsClosed(data.status) && userIsMaster) || userIsAdmin">
            <v-btn
              x-large
              block
              @click="remove"
              color="error">Удалить</v-btn>
          </v-col>
          <v-col cols="4" v-if="statusIsDraft(data.status) && userIsMaster">
            <v-btn
              x-large
              block
              :disabled="!readyForVerify"
              @click="verify"
              color="warning">На согласование</v-btn>
          </v-col>
          <v-col cols="4" v-if="statusIsVerify(data.status) && userIsAdmin">
            <v-btn
              x-large
              block
              :disabled="!readyForWork"
              @click="inwork"
              color="info">В работу</v-btn>
          </v-col>
          <v-col cols="4" v-if="statusIsWork(data.status) && userIsMaster">
            <v-btn
              x-large
              block
              :disabled="!readyForClose"
              @click="close1"
              color="success">Работа окончена</v-btn>
          </v-col>
          <v-col cols="4" v-if="statusIsDone(data.status) && userIsAdmin">
            <v-btn
              x-large
              block
              :disabled="!readyForClose"
              @click="close2"
              color="success">Закрыть</v-btn>
          </v-col>
        </v-row>

        <v-row align="end" justify="end" class="pt-10">
          <v-col cols="4" v-if="userIsAdmin">
            <v-btn
              x-large
              block
              outlined
              @click="print"
              color="primary"><v-icon class="mr-2">mdi-printer</v-icon>Печать</v-btn>
          </v-col>
          <v-col cols="4">
            <v-btn
              x-large
              block
              outlined
              color="primary"
              to="/statement-list">Назад к списку</v-btn>
          </v-col>
        </v-row>
        
      </v-card-text>
    </v-card>

    <!-- <PrintStatement 
      :data="data"
      :prices="printPrices"
    /> -->

    <v-card :loading="loadingHistory" v-if="data.equipment && history.length" class="my-5">
      <v-card-title dark>
        История ремонта "{{data.equipment.name}} - ({{data.equipment.slug}})"
      </v-card-title>
      <v-card-text style="font-size: large;">
        <v-expansion-panels>
          <v-expansion-panel v-for="item in history" :key="item.id">
            <v-expansion-panel-header class="title">{{item.created_date}} - {{item.updated_date}}</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row style="border-bottom: 1px solid #eee;">
                <v-col cols="3" class="py-1 font-weight-bold text-right">Номер ведомости</v-col>
                <v-col cols="9" class="py-1">{{item.slug}}</v-col>
              </v-row>
              <v-row style="border-bottom: 1px solid #eee;">
                <v-col cols="3" class="py-1 font-weight-bold text-right">Статус ведомости</v-col>
                <v-col cols="9" class="py-1">
                  <v-chip class="my-1" :color="getStatusColor(item.status)" dark>{{ item.status.name }}</v-chip>
                </v-col>
              </v-row>
              <v-row style="border-bottom: 1px solid #eee;">
                <v-col cols="3" class="py-1 font-weight-bold text-right">Клиент</v-col>
                <v-col cols="9" class="py-1">{{item.client.name}}</v-col>
              </v-row>
              <v-row style="border-bottom: 1px solid #eee;">
                <v-col cols="3" class="py-1 font-weight-bold text-right">Работы</v-col>
                <v-col cols="9" class="py-1">
                  <v-simple-table dense>
                    <template v-slot:default>
                      <tbody>
                        <tr v-for="(item, index) in item.works" :key="item.id">
                          <td class="py-1">{{index+1}}. {{ item.name }} ({{item.pivot.hours}} ч.)</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
              <v-row style="border-bottom: 1px solid #eee;">
                <v-col cols="3" class="py-1 font-weight-bold text-right">Материалы</v-col>
                <v-col cols="9" class="py-1">
                  <v-simple-table dense>
                    <template v-slot:default>
                      <tbody>
                        <tr v-for="(item, index) in item.parts" :key="item.id">
                          <td class="py-1">{{index+1}}. {{item.name}} ({{item.pivot.count}} {{item.unit}})</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
              <v-row v-if="item.comment_master" style="border-bottom: 1px solid #eee;">
                <v-col cols="3" class="py-1 font-weight-bold text-right">Комментарий мастера</v-col>
                <v-col cols="9" class="py-1">{{item.comment_master}}</v-col>
              </v-row>
              <v-row v-if="item.comment_admin">
                <v-col cols="3" class="py-1 font-weight-bold text-right">Комментарий администратора</v-col>
                <v-col cols="9" class="py-1">{{item.comment_admin}}</v-col>
              </v-row>
              <v-row align="end" justify="end" v-if="userIsMaster && statusIsDraft(data.status)">
                <v-col cols="8">
                  <v-btn
                    x-large
                    block
                    outlined
                    @click="copyFromHistory(data.id, item.id)"
                    color="secondary"><v-icon class="mr-2">mdi-call-made</v-icon>Скопировать работы и материалы</v-btn>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
    </v-card>

    <ErrorDialog
      v-model="dialogErr"
      title="Ошибка"
      text="В процессе сохранения данных произошла ошибка. Проверьте работу сети и повторите попытку позже."
      >
    </ErrorDialog>

  </v-container>

</template>

<script>
  import axios from 'axios'
  import StatementHeader from '@/components/StatementHeader'
  import PartsList from '@/components/PartsList'
  import PartsListConfirmed from '@/components/PartsListConfirmed'
  import PartsListAdmin from '@/components/PartsListAdmin'
  import WorksList from '@/components/WorksList'
  import WorksListAdmin from '@/components/WorksListAdmin'
  import ClientsInput from '@/components/ClientsInput'
  import EquipmentsInput from '@/components/EquipmentsInput'
  import CommentInput from '@/components/CommentInput'
  import ErrorDialog from '@/components/ErrorDialog'
  import TotalPrice from '@/components/TotalPrice'
  import PrintStatement from '@/components/PrintStatement'

  export default {
    components: {
      axios,
      StatementHeader,
      PartsList,
      PartsListConfirmed,
      PartsListAdmin,
      ClientsInput,
      EquipmentsInput,
      WorksList,
      WorksListAdmin,
      CommentInput,
      ErrorDialog,
      TotalPrice,
      PrintStatement,
    },
    data: () => ({
      title: 'Дефектная ведомость',
      modname: 'statements',
      loading: true,
      loadingHistory: false,
      allowSave: false,
      dialogErr: false,
      data: {
        client: {},
        client_id: null,
        comment_admin: '',
        comment_master: '',
        created_at: '',
        created_date: '',
        updated_date: '',
        deleted_at: null,
        equipment: {},
        equipment_id: null,
        id: null,
        slug: '',
        status: {slug: 'draft'},
        status_id: null,
        updated_at: '',
        user_id: null,
        works: [],
        parts: [],
      },
      tempData: {},
      equipments: [],
      clients: [],
      works: [],
      parts: [],
      statuses: [],
      history: [],
      printPrices: true,
    }),

    created () {
      this.getAllData(() => {
        this.allowSave = true
      })
    },

    watch: {
      data: {
        handler: function () {
          this.save()
        },
        deep: true
      },
      'data.equipment_id': function (id, oldId) {
        if (id && id !== oldId) {
          this.getHistory()
        }
      },
    },

    computed: {
      confirmedParts () {
        return this.data.parts.filter(d => d.pivot.confirmed)
      },
      unconfirmedParts () {
        return this.data.parts.filter(d => !d.pivot.confirmed)
      },
      readyForVerify () {
        return this.data.client_id !== null && this.data.equipment_id !== null
      },
      readyForWork () {
        return this.readyForVerify
      },
      readyForClose () {
        return this.readyForWork
      },
    },

    methods: {
      updateEquipment (d) {
        this.data.equipment_id = d[0].id
        this.data.equipment = d[0]
      },
      insertEquipment (d) {
        this.equipments.push(d)
      },
      updateClient (d) {
        this.data.client_id = d[0].id
        this.data.client = d[0]
      },
      updateMasterComment (text) {
        this.data.comment_master = text
      },
      updateAdminComment (text) {
        this.data.comment_admin = text
      },
      unconfirmPart (id) {
        this.data.parts.find(d => d.id === id).pivot.confirmed = 0
      },
      confirmParts (ids) {
        ids.forEach(id => this.data.parts.find(d => d.id === id).pivot.confirmed = 1)
      },
      getStatement () {
        return this.$axios.get(this.modname + '/' + this.$route.params.id)
      },
      getHistory () {
        this.loadingHistory = true
        this.$axios.get(this.modname + '/history/' + this.data.id + '/' + this.data.equipment_id)
          .then(r => {
            if (r.data.length > 0) {
              this.history = r.data
            }
          })
          .catch(error => {
            this.saveError(error)
          })
          .finally(() => {
            this.loadingHistory = false
          })
      },
      copyFromHistory(to, from) {
        if (!confirm('Текущие работы и материалы будут заменены. Уверены?')) {
          return
        }
        this.loading = true
        this.$axios.get(this.modname + '/history_copy/' + to + '/' + from)
          .then(r => {
            if (r.data.id) {
              this.getAllData()
            }
          })
          .catch(error => {
            this.saveError(error)
          })
          .finally(() => {
            this.loading = false
          })
      },
      getEquipments () { return this.$axios.get('equipments') },
      getClients () { return this.$axios.get('clients') },
      getWorks () { return this.$axios.get('works') },
      getParts () { return this.$axios.get('parts') },
      getStatuses () { return this.$axios.get('statuses') },
      getAllData (callback) {
        axios.all([
          this.getStatement(), 
          this.getEquipments(), 
          this.getClients(), 
          this.getWorks(), 
          this.getParts(),
          this.getStatuses(),
        ])
          .then(axios.spread((statement, equipments, clients, works, parts, statuses) => {
            this.data = Object.assign(this.data, statement.data)
            this.equipments = equipments.data
            this.clients = clients.data
            // set default pivot param to works & parts
            this.works = works.data.map(d => Object.assign(d, {pivot: {hours: 1}}))
            this.parts = parts.data.map(d => Object.assign(d, {pivot: {count: 1, confirmed: 0}}))
            this.statuses = statuses.data
            if (typeof callback === 'function') {
              setTimeout(() => {
                callback()
              }, 2000)
            }
          }))
          .catch(error => {
            this.saveError(error)
          })
          .finally(() => {
            this.loading = false
          })
      },
      getStatementData () {
        axios.all([
          this.getStatement(), 
        ])
          .then(axios.spread((statement) => {
            this.data = Object.assign(this.data, statement.data)
          }))
          .catch(error => {
            this.saveError(error)
          })
          .finally(() => {
            this.loading = false
          })
      },
      save (callback) {
        if (!this.allowSave) {
          return
        }
        this.loading = true
        this.tempData = Object.assign({}, this.data)
        this.validate(this.data)
        this.$axios.patch(this.modname + '/' + this.$route.params.id, this.data)
          .then((r) => {
            if (r.data.result) {
              this.loading = false
              if (typeof callback === 'function') {
                callback()
              }
            } else {
              this.saveError(r)
            }
          })
          .catch(error => {
            this.saveError(error)
          })
          .finally(() => {
            this.loading = false
          })
      },
      saveError (error) {
        // eslint-disable-next-line
        console.error('Statement update error', error, this.data)
        this.data = Object.assign({}, this.tempData)
        this.openError()
      },
      remove () {
        if (!confirm('Удалить эту ведомость?')) {
          return
        }
        this.loading = true
        this.$axios.delete(this.modname + '/' + this.$route.params.id)
          .then((r) => {
            if (r.data.result) {
              this.$router.push('/statement-list')
            } else {
              this.saveError(r)
            }
          })
          .catch(error => {
            this.saveError(error)
          })
          .finally(() => {
            this.loading = false
          })
      },
      validate (data) {
        this.allowSave = false
        data.parts.forEach((d) => {
          d.price = d.price.length === 0 ? 1 : d.price
          Object.assign(d.pivot, {
            price: d.pivot.price || d.price, 
            count: d.pivot.count || 1
          })
        })
        data.works.forEach((d) => {
          d.price = d.price.length === 0 ? 1 : d.price
          Object.assign(d.pivot, {
            price: d.pivot.price || d.price, 
            hours: d.pivot.hours || 1
          })
        })
        this.allowSave = true
      },
      verify () {
        this.data.status_id = this.statusGetVerify(this.statuses).id
        this.save(() => {
          this.$router.push('/statement-list')
        })
      },
      inwork () {
        this.data.status_id = this.statusGetWork(this.statuses).id
        this.save(() => {
          this.getAllData()
        })
      },
      close1 () {
        this.data.status_id = this.statusGetDone(this.statuses).id
        this.save(() => {
          this.getAllData()
        })
      },
      close2 () {
        this.data.status_id = this.statusGetClosed(this.statuses).id
        this.save(() => {
          this.getAllData()
        })
      },
      openError () {
        this.dialogErr = !this.dialogErr
      },
      disableSaving () {
        this.allowSave = false
      },
      enableSaving () {
        this.allowSave = true
      },
      print () {
        if (confirm('Скрыть цены на печати?')) {
          this.printPrices = false
        } else {
          this.printPrices = true
        }
        setTimeout(() => {
          this.$htmlToPaper('print-statement')
        }, 1000)
      },
    },
  }
</script>

<style>
  .v-textarea textarea {
    line-height: 22px;
  }
</style>