<template>
  <div>
    <div class="title">{{title}} ({{confirmedItems.length}})</div>

    <v-row align="start" justify="center" v-if="unconfirmedItems.length === 0 && confirmedItems.length > 0">
      <v-col cols="12">
        <v-alert type="success">Все материалы выданы</v-alert>
      </v-col>
    </v-row>
    <v-row align="start" justify="center" v-if="data.length === 0">
      <v-col cols="12">
        <v-alert type="info">Материалы еще не добавлены</v-alert>
      </v-col>
    </v-row>
    <v-row align="start" justify="center" v-if="unconfirmedItems.length > 0">
      <v-col cols="12">
        <v-alert prominent type="warning">
          <v-row align="center">
            <v-col class="grow py-0">Выданы не все материалы</v-col>
            <v-col class="shrink py-0">
              <v-btn @click="confirm(allItemsIds)">Выдать все материалы</v-btn>
            </v-col>
          </v-row>
        </v-alert>
      </v-col>
    </v-row>

    <v-row align="start" justify="center" v-for="item in confirmedItems" :key="item.id">
      <v-col cols="3" md="5" class="d-flex pr-1">
        <v-badge color="success" overlap right style="width: 100%">
          <template v-slot:badge>
            <v-icon dark>
              mdi-check
            </v-icon>
          </template>
          <v-text-field label="Материал" v-model="item.name" block filled readonly></v-text-field>
        </v-badge>
      </v-col>
      <v-col class="px-1">
        <v-text-field label="Единица" v-model="item.unit" filled readonly></v-text-field>
      </v-col>
      <v-col class="px-1">
        <v-text-field
          label="Кол-во"
          min="1"
          max="999"
          step="1"
          v-model="item.pivot.count"
          @focus="disableSaving"
          @blur="enableSaving"
          filled
          background-color="grey lighten-5"
          type="number"></v-text-field>
      </v-col>
      <v-col class="px-1">
        <v-text-field
          label="Стоимость"
          type="number"
          min="1"
          max="999999999"
          step=".01"
          v-model="item.pivot.price"
          @focus="disableSaving"
          @blur="enableSaving"
          filled
          background-color="grey lighten-5"
          ></v-text-field>
      </v-col>
      <v-col class="px-1">
        <v-text-field
          label="Сумма"
          :value="fixed2(item.pivot.count * endPrice(item))"
          readonly
          filled
        ></v-text-field>
      </v-col>
      <v-col class="pl-1" v-if="!userIsMaster">
        <v-btn
          x-large
          block
          outlined
          color="error"
          class="px-2"
          @click="unconfirm(item.id)">Убрать</v-btn>
      </v-col>
    </v-row>

    <v-row align="start" justify="end">
      <!-- <v-col cols="4" md="3">
        <v-text-field
          label="Итого"
          :value="fixed2(totalPrice)"
          background-color="teal"
          dark
          readonly
          filled></v-text-field>
      </v-col> -->
    </v-row>

    <div class="title">{{title2}} ({{unconfirmedItems.length}})</div>
    
    <v-row align="start" justify="center" v-if="data.length === 0">
      <v-col cols="12">
        <v-alert type="warning">Материалы еще не добавлены</v-alert>
      </v-col>
    </v-row>

    <v-row align="start" justify="center" v-for="item in unconfirmedItems" :key="item.id">
      <v-col class="pr-1">
        <v-btn
          x-large
          block
          outlined
          color="success"
          class="px-2"
          @click="confirm([item.id])">Выдать</v-btn>
      </v-col>
      <v-col cols="4" md="6" class="d-flex px-1">
        <v-text-field label="Материал" v-model="item.name" filled readonly></v-text-field>
      </v-col>
      <v-col class="px-1">
        <v-text-field label="Единица" v-model="item.unit" filled readonly></v-text-field>
      </v-col>
      <v-col class="px-1">
        <v-text-field
          v-if="item.pivot"
          label="Количество"
          type="number"
          min="1"
          max="999"
          step="1"
          v-model="item.pivot.count"
          @focus="disableSaving"
          @blur="enableSaving"
          filled
          background-color="grey lighten-5"
          ></v-text-field>
      </v-col>
      <v-col class="px-1">
        <v-text-field
          label="Стоимость"
          type="number"
          min="1"
          max="999999999"
          step=".01"
          v-model="item.pivot.price"
          @focus="disableSaving"
          @blur="enableSaving"
          filled
          background-color="grey lighten-5"
          ></v-text-field>
      </v-col>
      <v-col class="px-1">
        <v-text-field
          label="Сумма"
          :value="fixed2(item.pivot.count * endPrice(item))"
          readonly
          filled
        ></v-text-field>
      </v-col>
      <v-col class="pl-1">
        <v-btn
          x-large
          block
          outlined
          color="error"
          class="px-2"
          @click="data.splice(data.indexOf(item), 1)">Удалить</v-btn>
      </v-col>
    </v-row>

    <v-row align="start" justify="end">
      <!-- <v-col cols="6" md="5" offset-md="5">
        <v-btn x-large block color="success">Скопировать из предыдущей</v-btn>
      </v-col> -->
      <v-col cols="4" md="3">
        <v-text-field
          label="Итого"
          :value="fixed2(totalPrice)"
          background-color="teal"
          dark
          readonly
          filled></v-text-field>
      </v-col>
      <v-col cols="4" md="2">
        <v-btn
          x-large
          block
          color="primary"
          :disabled="statusIsClosed(status)"
          @click="dialog = true">Добавить</v-btn>
      </v-col>
    </v-row>

    <!-- DIALOG -->
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Добавить - {{title}}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Поиск"
              class="my-5"
              single-line
              hide-details
            ></v-text-field>
            <v-data-table
              v-model="tempItems"
              :headers="[{text: 'Наименование', value: 'name', width: '100%'}]"
              :items="filteredItems"
              :single-select="false"
              :items-per-page="8"
              :search="search"
              :custom-filter="customFilter"
              @click:row="selectRow"
              hide-default-footer
              item-key="id"
              show-select
              class="elevation-1"
              :page.sync="page"
              @page-count="pageCount = $event"
            >
            </v-data-table>
            <div class="text-center pt-2">
              <v-pagination v-model="page" :length="pageCount"></v-pagination>
            </div>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn x-large class="px-5" color="secondary" @click="tempItems = [], dialog = false">Отмена</v-btn>
          <v-btn x-large class="px-5" color="info" @click="assignItems(), dialog = false">Выбрать</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
  export default {
    props: {
      title: String,
      title2: String,
      data: Array,
      items: Array,
      status: Object,
    },
    data () {
      return {
        dialog: false,
        tempItems: [],
        page: 1,
        pageCount: 0,
        search: '',
        mask: '####-####-####-####',
      }
    },
    computed: {
      confirmedItems () {
        return this.data.filter(d => d.pivot.confirmed)
      },
      unconfirmedItems () {
        return this.data.filter(d => !d.pivot.confirmed)
      },
      filteredItems () {
        return this.items.filter(i => !this.data.find(d => d.id === i.id))
      },
      allItemsIds () {
        return this.data.map(d => d.id)
      },
      totalPrice () {
        return this.data.map(d => d.pivot.count * this.endPrice(d))
          .reduce(function(acc, val) { return acc + val; }, 0)
      },
    },
    methods: {
      unconfirm (id) {
        this.$emit('remove-item', id)
      },
      confirm (ids) {
        this.$emit('confirm-item', ids)
      },
      assignItems () {
        this.tempItems.forEach((p) => {
          if (!this.data.find(d => d.id === p.id)) {
            this.data.push(this.items.find(i => i.id === p.id))
          }
        })
        this.tempItems = []
      },
      selectRow (row) {
        if (this.tempItems.includes(row)) {
          this.tempItems.splice(this.tempItems.indexOf(row), 1)
        } else {
          this.tempItems.push(row)
        }
      },
      disableSaving () {
        this.$emit('disable-saving')
      },
      enableSaving () {
        this.$emit('enable-saving')
        this.save()
      },
      save () {
        this.$emit('save')
      },
      customFilter (value, search) {
        return value != null &&
          search != null &&
          typeof value === 'string' &&
          this.checkSubstrings(search.split(' '), value)
      },
      checkSubstrings(searches, value) {
        for (let i = 0; i < searches.length; i++) {
          if (!value.toString().toLocaleLowerCase().includes(searches[i].toString().toLocaleLowerCase())) {
            return false
          }
        }
        return true
      }
    },
  }
</script>